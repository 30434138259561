import React from "react"

import Layout from '../components/Layout'
import CTAStart from "../components/CTAStart"
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Pricing = () => {
    return (
        <Layout>
        <SEO title={"Pricing"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-8">
                            Pricing
                    </h1>
                    </div>
                </div>
                <div className="rbs-bg bg-03"></div>
            </div>

            <div className="rbs-section">
                <div className="rbs-bg bg-03"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-12">
                            Seed
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Funding</h3>
                                <p className="body-l text-rbs-black-50">First round</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Revenue</h3>
                                <p className="body-l text-rbs-black-50">MRR &#60; €10K</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Product</h3>
                                <p className="body-l text-rbs-black-50">Launching MVP</p>
                            </div>
                        </div>
                        <div className="mb-12">
                            <h3 className="title-s-b">Includes</h3>
                            <p className="body-l text-rbs-black-50">
                                2 week sprint<br />
                                Brand strategy, visual identity, manual<br />
                                Post-sprint support call<br />
                                6 brand applications
                            </p>
                        </div>
                        <div>
                            <h3 className="title-s-b">Investment</h3>
                            <p className="body-l text-rbs-black-50">
                                €10K
                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-12">
                            Venture
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Funding</h3>
                                <p className="body-l text-rbs-black-50">Raised €1M+</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Revenue</h3>
                                <p className="body-l text-rbs-black-50 mb-4">MRR &#62; €20K</p>
                                <p className="body text-rbs-black-50">Alternatively, a spin-off or product pilot of an established company behaving like an internal startup</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Product</h3>
                                <p className="body-l text-rbs-black-50 mb-4">Product Market Fit</p>
                            </div>

                        </div>
                        <div className="mb-12">
                            <h3 className="title-s-b">Includes</h3>
                            <p className="body-l text-rbs-black-50">
                                2 week sprint<br />
                                Brand strategy, visual identity, manual<br />
                                Post-sprint support call<br />
                                8 brand applications
                            </p>
                        </div>
                        <div>
                            <h3 className="title-s-b">Investment</h3>
                            <p className="body-l text-rbs-black-50">
                                €20K
                        </p>
                        </div>
                    </div>
                </div>
                <div className="rbs-bg bg-03"></div>
            </div>

            <div className="rbs-section">
                <div className="rbs-bg bg-03"></div>
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-12">
                            Established
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Stage</h3>
                                <p className="body-l text-rbs-black-50">Scaling sales</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Revenue</h3>
                                <p className="body-l text-rbs-black-50">MRR > €200K</p>
                            </div>
                        </div>
                        <div className="mb-12">
                            <p className="body text-rbs-black-50">
                                This option is out of the scope of the Remote Brand Sprint. It will take around 2–6 months. Nevertheless, we are able to still provide a more effective and fitting branding framework for most startups than traditional brand boutiques are. We’ll design a custom process fitting the needs of your company. There will be a bigger team involved, more brand applications, docmentation. Contact us and let’s work out a custom solution for your needs.
                            </p>
                        </div>

                        <div>
                            <h3 className="title-s-b">Typical Investment</h3>
                            <p className="body-l text-rbs-black-50">
                                €20–80K
                        </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h2 className="title-l mb-12">
                            Optional Extras
                        </h2>
                        <div className="grid-a">
                            <div>
                                <h3 className="title-s-b">Website</h3>
                                <p className="body-l text-rbs-black-50 mb-4">Marketing landingpage design concept and design template mockups</p>
                                <p className="body text-rbs-black-50 mb-4">40% of the sprint option fee</p>
                                <p className="body text-rbs-black-50 mb-4">Requires an extra 1 week sprint and 2 remote client meetings</p>
                                <p className="body text-rbs-black-50">Delivered 1-2 weeks after the sprint</p>
                            </div>
                            <div>
                                <h3 className="title-s-b">Applications</h3>
                                <p className="body-l text-rbs-black-50 mb-4">Extra brand applications</p>
                                <p className="body text-rbs-black-50 mb-4">€200–500 each based on the scope of the design</p>
                                <p className="body text-rbs-black-50">Delivered 1-2 weeks after the sprint</p>
                            </div>

                        </div>
                        
                    </div>
                </div>
                <div className="rbs-bg bg-03"></div>
            </div>

            <CTAStart />
        </Layout>
    )
}

export default Pricing